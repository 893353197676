<template>
  <div>
    <v-container fluid class="px-6 py-6 all">
      <Loading v-if="loading"></Loading>
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row class="container-print"> </v-row>
    </v-container>
  </div>
</template>

<script>
// import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js/auto";
import SalesCountry from "./Widgets/SalesCountry.vue";
import WidgetGlobe from "./Widgets/Globe.vue";
import dashboardService from "../../services/dashboard.service";
import Notify from "../Current/Components/Notify";

import Loading from "../Current/Components/Loading";
export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    Loading,
    Notify,
    WidgetGlobe,
  },
  data: function () {
    return {
      message: {
        msg: null,
        type: null,
      },
      loading: false,
    };
  },

  mounted() {
    document.title = this.$i18n.t("sidebar.Dashboard");
  },
};
</script>
<style scoped>
.select_by {
  display: inline-flex;
  width: 50%;
}

.fix-hight {
  min-height: 395px;
}

#chartLine {
  min-height: 342px;
}
</style>